import { render, staticRenderFns } from "./Passwordreset.vue?vue&type=template&id=e0ffb522&scoped=true"
import script from "./Passwordreset.vue?vue&type=script&lang=js"
export * from "./Passwordreset.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0ffb522",
  null
  
)

export default component.exports
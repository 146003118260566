import { render, staticRenderFns } from "./AppBase.vue?vue&type=template&id=79473f22"
import script from "./AppBase.vue?vue&type=script&lang=js"
export * from "./AppBase.vue?vue&type=script&lang=js"
import style0 from "./AppBase.vue?vue&type=style&index=0&id=79473f22&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports